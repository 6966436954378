// extracted by mini-css-extract-plugin
export var lbContainerOuter = "H_vV";
export var lbContainerInner = "H_vW";
export var movingForwards = "H_vX";
export var movingForwardsOther = "H_vY";
export var movingBackwards = "H_vZ";
export var movingBackwardsOther = "H_v0";
export var lbImage = "H_v1";
export var lbOtherImage = "H_v2";
export var prevButton = "H_v3";
export var nextButton = "H_v4";
export var closing = "H_v5";
export var appear = "H_v6";