// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_sL d_fp d_bG d_dv";
export var alignLeft = "t_pY d_fp d_bG d_dv";
export var alignDiscCenter = "t_sM d_fq d_bD d_dw";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignDiscRight = "t_sN d_fr d_bH d_dx";
export var alignRight = "t_pZ d_fr d_bH d_dx";
export var footerContainer = "t_sP d_dW d_bW";
export var footerContainerFull = "t_sQ d_dT d_bW";
export var footerHeader = "t_kf d_kf";
export var footerTextWrapper = "t_sR d_w";
export var footerDisclaimerWrapper = "t_km d_km d_ck";
export var badgeWrapper = "t_sS d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "t_sT d_bz d_bJ d_bN d_bL";
export var wide = "t_sV d_cy";
export var right = "t_sW d_bK";
export var line = "t_fk d_fl d_cv";
export var badgeDisclaimer = "t_sX d_bC d_bP d_bJ";
export var badgeContainer = "t_sY d_bz d_bH d_bP";
export var badge = "t_sZ";
export var padding = "t_s0 d_c7";
export var end = "t_s1 d_bH";
export var linkWrapper = "t_s2 d_dB";
export var link = "t_mC d_dB";
export var colWrapper = "t_s3 d_cx";
export var consent = "t_f d_f d_bC d_bP";
export var disclaimer = "t_s4 d_bz d_bJ";
export var media = "t_s5 d_bx d_dy";
export var itemRight = "t_s6";
export var itemLeft = "t_s7";
export var itemCenter = "t_s8";
export var exceptionWeight = "t_s9 q_rm";