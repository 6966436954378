// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_tb d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_tc d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_td d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_tf d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_tg d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_th d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_tj d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_rL q_rL";
export var heroExceptionNormal = "v_rM q_rM";
export var heroExceptionLarge = "v_rN q_rN";
export var Title1Small = "v_rq q_rq q_qW q_qX";
export var Title1Normal = "v_rr q_rr q_qW q_qY";
export var Title1Large = "v_rs q_rs q_qW q_qZ";
export var BodySmall = "v_rF q_rF q_qW q_rd";
export var BodyNormal = "v_rG q_rG q_qW q_rf";
export var BodyLarge = "v_rH q_rH q_qW q_rg";