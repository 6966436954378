// extracted by mini-css-extract-plugin
export var noPadding = "B_fj d_fj";
export var videoFrameWrapper = "B_tV d_kS d_b5";
export var videoIframeStyle = "B_qg d_d5 d_w d_H d_by d_b1 d_R";
export var videoLayoutRight = "B_tW d_kR d_bK";
export var videoInnerWrapper = "B_tX d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5";
export var videoText = "B_tY d_dw d_w";
export var videoInnerInnerWrapper = "B_tZ d_bz d_bD d_bJ";
export var btnWrapper = "B_d2 d_w d_bz d_bD d_cj";
export var btn = "B_mB d_b1 d_bz d_bD d_bP d_cH d_b6 d_dB d_n q_rK q_rp";
export var icon = "B_qz d_cC";
export var textLeft = "B_dv";
export var textCenter = "B_dw";
export var textRight = "B_dx";