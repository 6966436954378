// extracted by mini-css-extract-plugin
export var socialContainer = "z_tG";
export var instagramLink = "z_tH";
export var socialWrapperLeft = "z_nX d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "z_tJ";
export var socialContentContainerFull = "z_tK";
export var instagramContainer = "z_tL";
export var twitterContainer = "z_tM";
export var facebookContainer = "z_tN";
export var socialErrorContainer = "z_tP";
export var facebookContainerWide = "z_tQ";
export var twitterContainerWide = "z_tR";
export var socialParagraphCenter = "z_tS";
export var instaWrapper = "z_tT";
export var SubtitleSmall = "z_qC q_qC q_qW q_q6";
export var SubtitleNormal = "z_qD q_qD q_qW q_q7";
export var SubtitleLarge = "z_qF q_qF q_qW q_q8";
export var textLeft = "z_dv";
export var textCenter = "z_dw";
export var textRight = "z_dx";