// extracted by mini-css-extract-plugin
export var alignLeft = "w_pY d_bG";
export var alignCenter = "w_bP d_bD";
export var alignRight = "w_pZ d_bH";
export var textAlignLeft = "w_tk";
export var textAlignCenter = "w_tl";
export var textAlignRight = "w_tm";
export var hoursInnerWrapperAlt = "w_tn d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "w_tp d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "w_tq d_dw";
export var titleMargin = "w_tr d_cw";
export var hoursInnerInnerWrapper = "w_ts d_cz";